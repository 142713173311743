import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  FormControl,
  IconButton,
  MenuItem,
  Menu,
  Card,
  Container,
} from "@mui/material";
import { Small } from "../StyledComponents/StyledComponents";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditDialog from "./EditDialog";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import { fetchInsuranceDetails } from "../Api/Axios";
import NavBar from "./NavBar";

const options = ["Edit"];

const Status = () => {
  const [data, setData] = useState([]);
  const [anchorEls, setAnchorEls] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const logo = process.env.REACT_APP_PRIMARY_ICON;
  useEffect(() => {
    fetchInsuranceDetails(page)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data);
          setTotalPages(response.data.data.total_pages);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page]);

  const handleMenuOpen = (event, index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: event.currentTarget }));
  };

  const handleMenuClose = (index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: null }));
  };

  const handleEditClick = (claimId, status) => {
    setSelectedClaimId(claimId);
    setIsDialogOpen(true);
    setStatus(status);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const statusChip = (status) => {
    if (status === "Submitted")
      return <Small bgcolor={"#cca300"}> Submitted </Small>;
    else if (status === "More Info Required")
      return <Small bgcolor={"#ff6600"}> More Info Required </Small>;
    else if (status === "Accepted")
      return <Small bgcolor={"#006600"}> Accepted </Small>;
    else if (status === "Denied")
      return <Small bgcolor={"#ff0000"}> Denied </Small>;
    else if (status === "Paid")
      return <Small bgcolor={"#006600"}> Paid </Small>;
  };

  return (
    <Box>
      <NavBar />
      <Container>
        <Box width="100%" mt={3}>
          <Grid container spacing={2}>
            <Card
              sx={{
                padding: "20px 24px",
                overflowX: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <Table
                sx={{
                  // border: "3px solid #99bbff",
                  display: "block",
                  maxHeight: "500px",
                  overflow: "auto",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                    display: "table",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: logo === "axis" ? "#81003c" : " #ccddff",
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Claim ID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Full Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Policy Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: logo === "axis" ? "#ffffff" : "#0000b3" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                // sx={{
                //   display: "block",
                //   maxHeight: "500px",
                //   overflow: "auto",
                //   width: "100%",
                //   tableLayout: "fixed",
                // }}
                >
                  {data.result && data.result.length > 0 ? (
                    data.result.map((row) => (
                      <TableRow
                        hover
                        key={row.claim_id}
                        sx={{
                          display: "table",
                          width: "100%",
                          tableLayout: "fixed",
                        }}
                      >
                        <TableCell align="center">{row.claim_id}</TableCell>
                        <TableCell align="center">{row.fullname}</TableCell>
                        <TableCell align="center">{row.phone_number}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.policy_name}</TableCell>
                        <TableCell align="center">
                          {statusChip(row.status)}
                        </TableCell>
                        <TableCell align="center">
                          <FormControl sx={{ width: 10 }}>
                            <IconButton
                              aria-label="more"
                              aria-controls={
                                Boolean(anchorEls[row.claim_id])
                                  ? "long-menu"
                                  : undefined
                              }
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuOpen(event, row.claim_id)
                              }
                              sx={{
                                color: logo === "axis" ? "#81003c" : "#0000b3",
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEls[row.claim_id]}
                              open={Boolean(anchorEls[row.claim_id])}
                              onClose={() => handleMenuClose(row.claim_id)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  onClick={() => {
                                    handleMenuClose(row.claim_id);
                                    if (option === "Edit")
                                      handleEditClick(row.claim_id, row.status);
                                  }}
                                >
                                  <BuildRoundedIcon
                                    sx={{
                                      color:
                                        logo === "axis" ? "#81003c" : "#80a8ff",
                                    }}
                                  />
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <TableCell colSpan={7} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                />
              </Grid>
            </Card>
          </Grid>
        </Box>
      </Container>

      {isDialogOpen && (
        <EditDialog
          setIsDialogOpen={setIsDialogOpen}
          isDialogOpen={isDialogOpen}
          selectedClaimId={selectedClaimId}
          setSelectedClaimId={setSelectedClaimId}
          status={status}
          setStatus={setStatus}
          data={data}
        />
      )}
    </Box>
  );
};

export default Status;
