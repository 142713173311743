import styled from "@emotion/styled";

import {
  Button,
  Chip,
  Dialog,
  Divider,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const StyledButton = styled(Button)(() => ({
  textTransform: "capitalize",
  fontFamily: "Poppins",
  backgroundColor: "#1963AA !important",
  color: "#FFFFFF !important",
  width: "90%",
  borderRadius: "10px",
  padding: "8px",
}));

export const StyledFileButton = styled(Button)(() => ({
  textTransform: "capitalize",
  fontFamily: "Poppins",

  backgroundColor: "#1963AA !important",
  color: "#FFFFFF !important ",
  borderRadius: "20px",
  padding: "16px",
}));

export const StyledFormButton = styled(Button)(() => ({
  fontFamily: "Poppins",
  textTransform: "capitalize",
  backgroundColor: "#1963AA",
  color: "#FFFFFF",
  borderRadius: "10px",
  padding: "8px",
  fontSize: "14px",
}));

export const StyledFileUploadButton = styled(Button)(() => ({
  textTransform: "capitalize",
  backgroundColor: "#1963AA",
  fontFamily: "Poppins",
  width: "90%",
  color: "#FFFFFF",
  borderRadius: "10px",
  padding: "8px",
  fontSize: "14px",
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: "13px",
  fontWeight: "500",
}));

export const StyledDivider = styled(Divider)(() => ({
  orientation: "vertical",
  color: "#575C62",
}));

export const StyledSpan = styled("span")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
}));

export const StyledTitle = styled("p")(() => ({
  fontWeight: "600",
  fontSize: "16px",
}));

export const StyledChip = styled(Chip)(() => ({
  padding: "12px",
  borderRadius: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  fontSize: "12px",
}));

export const StyledStack = styled(Stack)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 9,
  justifyContent: "center",
}));

export const StyledFooter = styled("p")(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "rgba(0, 0, 0, 0.6)",
}));

export const StyledAddText = styled("p")(() => ({
  color: "rgba(0, 105, 187, 1)",
  fontSize: "13px",
  lineHeight: "20px",
  fontWeight: 500,
}));
export const StyledTextAreaAutoSize = styled(TextareaAutosize)(({ theme }) => ({
  border: "1px solid",
  borderRadius: "14px",
  borderColor: "lightgrey",
  padding: "18px",
  resize: "none",
  fontSize: "14px",
  overflow: "hidden",
  overflowY: "auto",
  lineHeight: "1.5",
  focus: "none",
  color: "#1963AA",
  outline: "none",
  // "& .MuiInputBase-root input": {
  //   color: "#1963AA",
  //   fontWeight: 500,
  // },
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
}));

export const StyledDatePicker = styled(DatePicker)(() => ({
  "& .MuiInputLabel-root": {
    fontSize: "14.5px",
    color: "#1963AA",
  },
  ".MuiInputBase-root": {
    fontSize: "13px",
    borderRadius: "12px",
    padding: "0px 0px 0px 0px ",
  },
  "& .MuiInputBase-root input": {
    color: "#1963AA",
    fontWeight: 500,
  },

  ".MuiSvgIcon-root": {
    width: "48px",
    height: "26px",
    padding: "2px 18px 2px 2px ",
    color: "#1963AA",
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputLabel-root": {
    fontSize: "13px",
    color: "#1963AA !important",
  },

  "& .MuiInputBase-root": {
    fontSize: "13px",
    padding: "2.5px 2.5px 2.5px 2.5px",
    borderRadius: "12px",
    color: "red",
    borderColor: "#1963AA !important",
  },
  "& .MuiInputBase-root input": {
    // color: "#0069BB",
    color: "#1963AA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    borderColor: "#1963AA !important",
  },
}));

export const StyledText = styled("p")(() => ({
  backgroundColor: "white",
  padding: 10,
  borderRadius: "1rem",
  fontStyle: "normal",
}));

export const StyledCommon = styled("p")(() => ({
  color: "#1963AA",
  fontSize: "12px",
}));
export const Small = styled("small")(({ bgcolor }) => ({
  // height: 15,
  // width: 50,
  // color: "#fff",
  color: bgcolor,
  fontSize: 13,
  fontWeight: 900,
  // padding: "2px 9px",
  // borderRadius: "9px",
  // overflow: "hidden",
  // background: bgcolor,
  // boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));
