import React from "react";
import Status from "./Components/Status";

export default function App() {
  return (
    <>
      <Status />
    </>
  );
}
