// import React from "react";
// import {
//   Dialog,
//   DialogActions,
//   DialogTitle,
//   FormControl,
//   Button,
//   Autocomplete,
//   TextField,
// } from "@mui/material";
// import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
// import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// import { updateInsuranceStatus } from "../Api/Axios"; // Ensure you import your API function

// const EditDialog = ({
//   setIsDialogOpen,
//   isDialogOpen,
//   selectedClaimId,
//   setSelectedClaimId,
//   setStatus,
//   status,
// }) => {
//   const logo = process.env.REACT_APP_PRIMARY_ICON;
//   const styles = {
//     icon: {
//       marginRight: "8px",
//       fontSize: "32px",
//       color: "black",
//     },
//     saveIcon: {
//       "&:hover": {
//         color: "#ffffff",
//       },
//     },
//     cancelIcon: {
//       "&:hover": {
//         color: "#ffffff",
//       },
//     },
//     button: {
//       textTransform: "none",
//       backgroundColor: "#ffffff",
//       border: `1px solid ${logo === "axis" ? "#81003c" : "#80a8ff"}`,
//       borderRadius: "8px",
//       padding: "8px 16px",
//       margin: "8px",
//       color: "black",
//     },
//     saveButton: {
//       "&:hover": {
//         backgroundColor: logo === "axis" ? "#81003c" : "#80a8ff",
//         color: "#ffffff",
//       },
//     },
//     cancelButton: {
//       "&:hover": {
//         backgroundColor: "#cc0000",
//         color: "#ffffff",
//       },
//     },
//   };

//   const statusValues = [
//     { label: "More Info Required" },
//     { label: "Accepted" },
//     { label: "Denied" },
//     { label: "Paid" },
//   ];

//   const handleDialogClose = () => {
//     setIsDialogOpen(false);
//   };

//   const handleStatusChange = (event, newValue) => {
//     setStatus(newValue ? newValue.label : "");
//   };

//   const handleSave = async () => {
//     if (selectedClaimId && status) {
//       try {
//         await updateInsuranceStatus(selectedClaimId, status);
//       } catch (error) {
//         console.error("Failed to update status:", error);
//       }
//     } else {
//       console.error("Selected claim ID or status is missing");
//     }

//     handleDialogClose();
//   };

//   return (
//     <Dialog
//       open={isDialogOpen}
//       onClose={handleDialogClose}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//     >
//       <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
//         <h3 style={{ color: "black" }}>Edit</h3>
//       </DialogTitle>
//       <FormControl
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           marginTop: "10px",
//         }}
//       >
//         <Autocomplete
//           options={statusValues}
//           value={{ label: status }}
//           onChange={handleStatusChange}
//           disableClearable
//           sx={{ width: 200, marginBottom: "20px" }}
//           renderInput={(params) => (
//             <TextField {...params} label="Status" variant="outlined" disabled />
//           )}
//         />
//       </FormControl>
//       <DialogActions
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           marginBottom: "20px",
//         }}
//       >
//         <Button
//           onClick={handleSave}
//           sx={{ ...styles.button, ...styles.saveButton }}
//         >
//           <DoneAllOutlinedIcon style={styles.icon} />
//           Save
//         </Button>
//         <Button
//           onClick={handleDialogClose}
//           sx={{
//             ...styles.button,
//             ...styles.cancelButton,
//           }}
//         >
//           <ClearOutlinedIcon style={styles.icon} />
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default EditDialog;

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { updateInsuranceStatus } from "../Api/Axios"; // Ensure you import your API function

const EditDialog = ({
  setIsDialogOpen,
  isDialogOpen,
  selectedClaimId,
  setSelectedClaimId,
  setStatus,
  status,
}) => {
  const logo = process.env.REACT_APP_PRIMARY_ICON;
  const styles = {
    icon: {
      height: 20,
      width: 20,
      marginRight: "8px",
      fontSize: "32px",
      color: "black",
    },
    button: {
      height: 30,
      width: 90,
      textTransform: "none",
      backgroundColor: "#ffffff",
      border: `1px solid ${logo === "axis" ? "#81003c" : "#80a8ff"}`,
      borderRadius: "8px",
      padding: "8px 16px",
      margin: "8px",
      color: "black",
      "&:hover .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
    saveButton: {
      "&:hover": {
        backgroundColor: logo === "axis" ? "#81003c" : "#1a53ff",
        color: "#ffffff",
      },
    },
    cancelButton: {
      "&:hover": {
        backgroundColor: "#b30000",
        color: "#ffffff",
      },
    },
  };

  const statusValues = [
    { label: "More Info Required" },
    { label: "Accepted" },
    { label: "Denied" },
    { label: "Paid" },
  ];

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleStatusChange = (event, newValue) => {
    setStatus(newValue ? newValue.label : "");
  };

  const handleSave = async () => {
    if (selectedClaimId && status) {
      try {
        await updateInsuranceStatus(selectedClaimId, status);
      } catch (error) {
        console.error("Failed to update status:", error);
      }
    } else {
      console.error("Selected claim ID or status is missing");
    }

    handleDialogClose();
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <h3 style={{ color: "black" }}>Edit</h3>
      </DialogTitle>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Autocomplete
          options={statusValues}
          value={{ label: status }}
          onChange={handleStatusChange}
          disableClearable
          sx={{ width: 200, marginBottom: "20px" }}
          renderInput={(params) => (
            <TextField {...params} label="Status" variant="outlined" disabled />
          )}
        />
      </FormControl>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          onClick={handleSave}
          sx={{ ...styles.button, ...styles.saveButton }}
        >
          <DoneAllOutlinedIcon sx={styles.icon} />
          Save
        </Button>
        <Button
          onClick={handleDialogClose}
          sx={{ ...styles.button, ...styles.cancelButton }}
        >
          <ClearOutlinedIcon sx={styles.icon} />
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
