import React from "react";
import { Box, AppBar, Toolbar } from "@mui/material";
import icaniologo from "../assets/images/icaniologo.svg";
import axislogo from "../assets/images/axisheadmain.svg";
import { StyledTitle } from "../StyledComponents/StyledComponents";

const NavBar = () => {
  const logo = process.env.REACT_APP_PRIMARY_ICON;
  return (
    <div>
      <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <Box display="flex" alignItems="center" width="100%">
            <img
              src={logo === "axis" ? axislogo : icaniologo}
              // src={icaniologo}
              style={{ width: "40px", height: "40px", marginRight: "8px" }}
              alt="logo"
            />
            <StyledTitle className="text-secondtext" sx={{ color: "#000" }}>
              {logo === "axis" ? "AXIS" : "ICANIO"}
            </StyledTitle>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
