import axios from "axios";

const username = "admin";
const password = "admin-chatbot";
const basicAuthHeader = "Basic " + btoa(username + ":" + password);

const api = axios.create({
  baseURL: "https://dev-services-chatbot.icaniotech.com",
  headers: {
    Authorization: basicAuthHeader,
    "Content-Type": "application/json",
  },
});

export const fetchInsuranceDetails = (page) => {
  return api.get(`/admin/insurance/details/list?page_no=${page}`);
};

export const updateInsuranceStatus = (claimId, status) => {
  return api.put("/admin/insurance?claim_id=" + claimId + "&status=" + status);
};
